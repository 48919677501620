import PropTypes from "prop-types";
import NextLink from "next/link";

const Link = ({ href, external, children, ...props }) => {
  // Mailto links
  if (href.startsWith("mailto:")) {
    return (
      <button type="button" onClick={() => window.open(href)} {...props}>
        {children}
      </button>
    );
  }

  // External links
  if (external || !href.startsWith("/")) {
    return (
      <a href={href} rel="noopener noreferrer" target="_blank" {...props}>
        {children}
      </a>
    );
  }

  // Internal links
  return (
    <NextLink href={href}>
      <a {...props}>{children}</a>
    </NextLink>
  );
};

Link.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  external: PropTypes.bool,
};

Link.defaultProps = {
  href: "",
  children: null,
  external: false,
};

export default Link;
