import { motion } from "framer-motion";

import Link from "../components/Link";
import Logo from "../svg/logo.svg";
import EmailIcon from "../svg/email-icon.svg";

const IndexPage = () => (
  <div
    className="relative h-full flex justify-center items-center bg-zinc-900 bg-cover bg-center overflow-hidden"
    style={{ backgroundImage: 'url("/bg.png")' }}
  >
    <div className="w-full sm:w-[75vw] mx-auto px-8">
      <motion.div
        initial={{ opacity: 0, y: 25 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "linear", duration: 0.6 }}
      >
        <Logo className="w-48 sm:w-64 block" />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 25 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "linear", duration: 0.6, delay: 0.15 }}
      >
        <p className="text-xl sm:text-2xl text-zinc-300 mt-8 leading-relaxed">
          Custom woodworking based in <span className="text-brand">Lancaster, PA</span>
        </p>
      </motion.div>
    </div>
    <motion.div
      initial={{ opacity: 0, y: 25 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ type: "linear", duration: 0.6, delay: 0.3 }}
      className="absolute bottom-0 inset-x-0 flex justify-center px-8 pb-[12vh]"
    >
      <p className="text-base sm:text-xl text-zinc-300 flex items-center">
        <EmailIcon className="w-6 h-6 fill-current text-brand mr-3" />
        <span>
          <Link href="mailto:joshlasdindesign@gmail.com" className="text-brand hover:underline">
            Email me
          </Link>{" "}
          with commissions
        </span>
      </p>
    </motion.div>
  </div>
);

export default IndexPage;
